import React, { ReactElement } from "react";

import {
    Container,
    Heading,
    Links,
    LinkItem,
    Link,
} from "./CallToActionList.styled";
import CallToActionListProps from "./CallToActionListProps";

const CallToActionList = ({
    identifier,
    heading,
    items,
    contentId,
}: CallToActionListProps): ReactElement => {
    if (!items || items.length === 0) {
        return <></>;
    }
    return (
        <Container id={identifier}>
            <Heading id={`link-list-header-${identifier}`}>{heading}</Heading>
            <Links aria-labelledby={`link-list-header-${identifier}`}>
                {items?.map((item, index) => {
                    return (
                        <LinkItem key={`link-list-item-${contentId}-${index}`}>
                            <Link {...item} actionTheme="black" />
                        </LinkItem>
                    );
                })}
            </Links>
        </Container>
    );
};

export default React.memo(CallToActionList);
