import { GTMProvider } from "@elgorditosalsero/react-gtm-hook";
import { ISnippetsParams } from "@elgorditosalsero/react-gtm-hook/dist/models/GoogleTagManager"; /* eslint-disable-line import/no-unresolved */
import React, { ReactElement, ReactChild, useEffect } from "react";
import { StaticRouter, StaticRouterProps } from "react-router";
import {
    BrowserRouter,
    BrowserRouterProps,
    Route,
    Switch,
} from "react-router-dom";
import { StyleSheetManager } from "styled-components";

import BlockComponentSelector from "components/BlockComponentSelector";
import CookieConsent from "components/CookieConsent";
import Footer from "components/Footer";
import FooterSlim from "components/FooterSlim";
import Head from "components/Head";
import Header from "components/Header";
import PageComponentSelector from "components/PageComponentSelector";
import PageEditLink from "components/PageEditLink";
import SystemMessage from "components/SystemMessage";
import TelemetryProvider from "components/TelemetryProvider";
import {
    useFooter,
    usePageContent,
    useNavigation,
    useHeader,
    useEditMode,
    useGoogleTagManager,
    useAppState,
    useLanguagePickerPosition,
    useSystemMessage,
} from "hooks";
import useApplicationInsightsKey from "hooks/useApplicationInsightsKey";
import useCookieConsent from "hooks/useCookieConsent";
import NotFoundPage from "pages/NotFoundPage";
import { GlobalStyle } from "style/components/GlobalStyle";
import { addFullRefreshPropertiesAttributes } from "utils/episerver";
import getComponentTypeForContent from "utils/getComponentTypeForContent";

import { AppContainer, ContentContainer } from "./App.styled";

const App = (): ReactElement => {
    const Router: React.ComponentType<BrowserRouterProps | StaticRouterProps> =
        typeof window !== "undefined" ? BrowserRouter : StaticRouter;

    useEffect((): void => {
        // eslint-disable-next-line no-restricted-globals
        if ("scrollRestoration" in history) {
            // eslint-disable-next-line no-restricted-globals
            history.scrollRestoration = "manual";
        }
    });
    const googleTagManager = useGoogleTagManager();
    const pageContent = usePageContent();
    const navigations = useNavigation();
    const systemMessage = useSystemMessage();
    const header = useHeader();
    const footer = useFooter();
    const editMode = useEditMode();
    const cookieContent = useCookieConsent();
    const instrumentationKey = useApplicationInsightsKey();
    const languagePickerPosition = useLanguagePickerPosition();
    const { isTracking } = useAppState();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const routeRender = (_: any): ReactChild => {
        const languagePickerInHeader = languagePickerPosition === "header";
        return (
            <TelemetryProvider
                instrumentationKey={isTracking ? instrumentationKey : undefined}
            >
                <AppContainer>
                    <Head key={`head`} />
                    <ContentContainer key={`container`}>
                        {editMode.isInEditMode === false &&
                            pageContent &&
                            pageContent.pageEditUrl && (
                                <PageEditLink
                                    pageEditUrl={pageContent.pageEditUrl}
                                ></PageEditLink>
                            )}
                        {editMode.isInEditMode === true &&
                            editMode.fullRefreshProperties &&
                            editMode.fullRefreshProperties.length > 0 && (
                                <input
                                    type="hidden"
                                    {...addFullRefreshPropertiesAttributes(
                                        editMode.fullRefreshProperties,
                                    )}
                                />
                            )}
                        {systemMessage?.message && (
                            <SystemMessage
                                message={systemMessage.message}
                                link={systemMessage.link}
                                lastUpdated={systemMessage.lastUpdated}
                                l18n={systemMessage.l18n}
                            />
                        )}
                        {!(pageContent && pageContent.hideHeader) && (
                            <Header
                                key={`header`}
                                navigation={navigations.main}
                                mainLinks={navigations.mainLinks}
                                logoTitle={header.logoTitle}
                                startPageUrl={header.startPageUrl}
                                searchPageUrl={header.searchPageUrl}
                                loginPageText={header.loginPageText}
                                loginPageTitle={header.loginPageTitle}
                                loginPageUrl={header.loginPageUrl}
                                l18n={header.l18n}
                                sites={
                                    languagePickerInHeader
                                        ? footer.sites
                                        : undefined
                                }
                                searchOverlayVisible={
                                    header.searchOverlayVisible
                                }
                                visible={header.visible}
                            />
                        )}
                        <PageComponentSelector
                            key={`content`}
                            pageContent={pageContent}
                            componentSelector={getComponentTypeForContent}
                            NotFoundPage={NotFoundPage}
                        />
                        {!(pageContent && pageContent.hideContactFlyout) &&
                            pageContent.contactFlyout &&
                            Array.isArray(pageContent.contactFlyout) &&
                            pageContent.contactFlyout.length === 1 && (
                                <BlockComponentSelector
                                    componentSelector={
                                        getComponentTypeForContent
                                    }
                                    content={pageContent.contactFlyout[0]}
                                />
                            )}
                        {pageContent?.hideFooter ? (
                            <FooterSlim
                                key={`footer`}
                                personalIntegrityLinks={
                                    navigations.personalIntegrityLinks
                                }
                                logoTitle={footer.logoTitle}
                                l18n={footer.l18n}
                                sites={
                                    !languagePickerInHeader
                                        ? footer.sites
                                        : undefined
                                }
                            />
                        ) : (
                            <Footer
                                key={`footer`}
                                navigation={navigations.footer}
                                socialLinks={navigations.socialLinks}
                                personalIntegrityLinks={
                                    navigations.personalIntegrityLinks
                                }
                                socialLinksColumns={
                                    navigations.socialLinksColumns
                                }
                                logoTitle={footer.logoTitle}
                                columnsLimit={footer.columnsLimit}
                                l18n={footer.l18n}
                                sites={
                                    !languagePickerInHeader
                                        ? footer.sites
                                        : undefined
                                }
                            />
                        )}
                        {isTracking && cookieContent && (
                            <CookieConsent {...cookieContent} />
                        )}
                    </ContentContainer>
                </AppContainer>
            </TelemetryProvider>
        );
    };

    return (
        <GTMProvider
            state={
                isTracking
                    ? {
                          ...googleTagManager,
                      }
                    : ({ injectScript: false } as ISnippetsParams)
            }
        >
            <StyleSheetManager enableVendorPrefixes={true}>
                <GlobalStyle />
                <Router>
                    <Switch>
                        <Route path="*" render={routeRender} />
                    </Switch>
                </Router>
            </StyleSheetManager>
        </GTMProvider>
    );
};

export default React.memo(App);
