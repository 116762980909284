import { useCallback } from "react";

import useEventListener from "./useEventListener";

const useTabTrap = <T extends HTMLElement>(
    wrapperRef: React.RefObject<T>,
    isActive: boolean,
) => {
    const getTabbableElements = (container: HTMLElement | null) => {
        return container
            ? Array.from(
                  container.querySelectorAll<HTMLElement>(
                      'a, button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])',
                  ),
              ).filter((el) => !el.hasAttribute("disabled"))
            : [];
    };

    const handleTabKey = useCallback(
        (event: KeyboardEvent) => {
            if (!wrapperRef.current || !isActive) return;

            if (event.key !== "Tab") return;

            const tabbableElements = getTabbableElements(wrapperRef.current);
            if (tabbableElements.length === 0) {
                event.preventDefault();
                return;
            }

            const firstElement = tabbableElements[0];
            const lastElement = tabbableElements[tabbableElements.length - 1];

            if (event.shiftKey && document.activeElement === firstElement) {
                event.preventDefault();
                lastElement.focus();
            } else if (
                !event.shiftKey &&
                document.activeElement === lastElement
            ) {
                event.preventDefault();
                firstElement.focus();
            }
        },
        [wrapperRef, isActive],
    );

    useEventListener("keydown", (event: KeyboardEvent) => {
        if (!isActive) return;
        handleTabKey(event);
    });
};

export default useTabTrap;
