import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import LinkedImageProps from "./LinkedImageProps";

const LinkedImage = loadable(
    (): Promise<DefaultComponent<LinkedImageProps>> =>
        import(/* webpackChunkName: "LinkedImage" */ "./LinkedImage"),
);
const LinkedImageLoader = (props: LinkedImageProps): ReactElement => (
    <LinkedImage {...props} />
);
export default LinkedImageLoader;
