import React, { ReactElement, useRef, useState, useEffect } from "react";
import { ThemeProvider } from "styled-components";

import ContentArea from "components/ContentArea";
import Icon from "components/Icon/Icon";
import { useEventListener, useTabTrap } from "hooks";
import useAppState from "hooks/useAppState";
import { A11y } from "style/components/A11Y";
import getComponentTypeForContent from "utils/getComponentTypeForContent";

import {
    Container,
    Wrapper,
    Flyout,
    Dialog,
    ToggleButton,
    Content,
} from "./ContactFlyout.styled";
import ContactFlyoutProps from "./ContactFlyoutProps";

const ContactFlyout = ({
    label,
    icon,
    blocks,
    identifier,
    contentId,
}: ContactFlyoutProps): ReactElement => {
    const dialogRef = useRef<HTMLDivElement>(null);
    const flyoutRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const [open, setOpen] = useState(false);
    const iconSize = "56";
    const hasBlocks = blocks && blocks?.length > 0;
    const { fetchSuccess: isFetchedSuccess } = useAppState();

    // Close flyout when the page fetch completed
    useEffect(() => {
        if (isFetchedSuccess) {
            setOpen(false);
        }
    }, [isFetchedSuccess]);

    useTabTrap(flyoutRef, open);

    const handleButtonClick = (
        event: React.MouseEvent | React.KeyboardEvent,
    ) => {
        event.stopPropagation();
        setOpen((prev) => !prev);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (
            dialogRef.current &&
            !dialogRef.current.contains(event.target as Node) &&
            buttonRef.current !== event.target
        ) {
            setOpen(false);
        }
    };

    const handleEscapeKey = (event: KeyboardEvent) => {
        if (event.key === "Escape") {
            setOpen(false);
        }
    };

    useEventListener("click", handleClickOutside);
    useEventListener("keydown", handleEscapeKey);

    if (!hasBlocks) {
        return <></>;
    }

    return (
        <ThemeProvider
            theme={{
                open: open,
            }}
        >
            <Wrapper
                id={identifier}
                aria-labelledby={`contact-flyout-button-${contentId}`}
            >
                <Container>
                    <Flyout ref={flyoutRef}>
                        <ToggleButton
                            id={`contact-flyout-button-${contentId}`}
                            onClick={handleButtonClick}
                            aria-expanded={open}
                            aria-controls={`contact-flyout-dialog-${contentId}`}
                        >
                            <Icon
                                icon={open ? "close56" : `${icon}${iconSize}`}
                            />
                            <A11y>{label}</A11y>
                        </ToggleButton>
                        <Dialog
                            id={`contact-flyout-dialog-${contentId}`}
                            aria-hidden={!open}
                            role="dialog"
                            aria-label={label}
                            ref={dialogRef}
                        >
                            <Content>
                                <ContentArea
                                    items={blocks}
                                    componentSelector={
                                        getComponentTypeForContent
                                    }
                                />
                            </Content>
                        </Dialog>
                    </Flyout>
                </Container>
            </Wrapper>
        </ThemeProvider>
    );
};

export default React.memo(ContactFlyout);
