import { styled } from "styled-components";

import Button from "components/Button/Button";
import { styleHeadingS } from "style/components/Typography";

export const Container = styled.div`
    width: 100%;

    &:not(:first-child) {
        margin-top: 0.875rem;
    }
`;

export const Heading = styled.h2`
    ${styleHeadingS};
    color: var(--color-blue);
`;

export const Links = styled.ul`
    all: unset;
    display: flex;
    flex-direction: column;
    row-gap: 0.875rem;
`;

export const LinkItem = styled.li`
    all: unset;
    display: block;
`;

export const Link = styled(Button)`
    width: 100%;
`;
