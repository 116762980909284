import { styled } from "styled-components";
import theme from "styled-theming";

import { buttonDefaults } from "components/Button/Button.styled";
import { columnGap } from "style/grid";
import { MQ } from "style/mediaQueries";
import { BASE_MAX_SIZE } from "style/settings";

export const Wrapper = styled.aside`
    bottom: 1.75rem;
    display: flex;
    justify-content: center;
    position: fixed;
    width: 100%;
    z-index: 100;
`;

export const Container = styled.div`
    ${columnGap}
    display: grid;
    max-width: ${BASE_MAX_SIZE}px;
    padding: 0 1.75rem;
    width: 100%;

    ${MQ.FROM_M} {
        grid-template-columns: repeat(6, [col-start] 1fr);
    }
    ${MQ.FROM_L} {
        grid-template-columns: repeat(5, [col-start] 1fr);
    }
    ${MQ.FROM_XL} {
        grid-template-columns: repeat(3, [col-start] 1fr);
    }
`;

export const Flyout = styled.div`
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    position: relative;

    ${MQ.FROM_M} {
        grid-column: col-start 3 / span 4;
    }
    ${MQ.FROM_L} {
        grid-column: col-start 4 / span 2;
    }
    ${MQ.FROM_XL} {
        grid-column: col-start 3 / span 1;
    }
`;

export const Dialog = styled.div`
    background-color: var(--color-black-l95);
    border-radius: 4px 4px 0;
    bottom: 84px;
    box-shadow: 0 12px 28px rgb(0 0 0 / 5%);
    overflow: hidden;
    position: absolute;
    right: 0;
    width: 100%;

    &[aria-hidden="true"] {
        display: none;
    }
`;

export const Content = styled.div`
    max-height: calc(100svh - 185px);
    overflow: auto;
    padding: 1.3125rem 1.3125rem 2.625rem;

    ${MQ.FROM_M} {
        max-height: calc(100svh - 250px);
    }
`;

export const ToggleButton = styled.button`
    ${buttonDefaults};
    align-items: center;
    background-color: var(--color-black-l95);
    border: 0;
    border-radius: ${theme("open", {
        true: "0 0 50% 50%",
        false: "100%",
    })};
    bottom: 0;
    box-shadow: 0 12px 28px rgb(0 0 0 / 5%);
    color: var(--color-black);
    cursor: pointer;
    display: flex;
    height: 5.25rem;
    justify-content: center;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 0;
    transition: none;
    width: 5.25rem;
    z-index: 1;
`;
